import classNames from "classnames";
import { useState } from "react";

import {
  useCreateMembershipMutation,
  useDestroyMembership,
  useMembershipDetails,
  useMembershipSearch,
  useMembershipsFor,
  useOpenSettlements,
} from "@/data";
import { MembershipType, Settlement } from "@/types";
import { AgeGuide, Box, Input, MembershipTable, PageTitle } from "@/ui";
import { formatDateTime, useHashParams } from "@/utils";

const HEADER = <PageTitle text="Register new membership" />;

export function RegisterPage() {
  const openSettlements = useOpenSettlements();
  const params = useHashParams();
  const settlementId = params.get("settlementId");

  if (openSettlements.isLoading) {
    return <Loading />;
  }

  const settlements = openSettlements.data!;
  const selectedSettlement = settlements.find((s) => s.id === settlementId);

  if (selectedSettlement)
    return (
      <Register
        settlement={selectedSettlement}
        onCancel={() => {
          window.location.hash = "#/memberships/register";
        }}
      />
    );

  if (settlements.length === 0)
    return (
      <Box primary title="Register membership">
        <div className="alert alert-info max-w-prose">
          <p>Memberships are currently closed for registration.</p>
        </div>
      </Box>
    );

  return (
    <PickSettlement
      settlements={settlements}
      onChoose={(id) => {
        window.location.hash = `#/memberships/register?settlementId=${id}`;
      }}
    />
  );
}

function Loading() {
  return (
    <>
      {HEADER}
      <p>Loading...</p>
    </>
  );
}

function PickSettlement({
  settlements,
  onChoose,
}: {
  settlements: Settlement[];
  onChoose: (id: string) => void;
}) {
  return (
    <Box title="Register memberships" primary>
      <div className="alert alert-info max-w-prose">
        <div>
          <p>
            For accounting purposes all memberships must be connected to a{" "}
            settlement/oppgjør. <strong>Do not register a memberships</strong>{" "}
            unless you're absolutely sure you have the right settlement in the
            list below.
          </p>
        </div>
      </div>

      <div className="mt-8" />

      <div className="flex flex-wrap">
        {settlements.map((settlement) => (
          <div
            key={settlement.id}
            className="card w-96 bg-base-200 shadow-xl mr-8 mb-8 border border-secondary"
          >
            <div className="card-body">
              <h2 className="card-title">{settlement.name}</h2>
              <p>
                Created by <strong>{settlement.creator.name}</strong> on{" "}
                {formatDateTime(new Date(settlement.createdAt))}.
              </p>
              <div className="card-actions justify-end">
                <button
                  className="btn btn-primary"
                  onClick={(evt) => {
                    evt.preventDefault();
                    onChoose(settlement.id);
                  }}
                >
                  Choose
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Box>
  );
}

function Register({
  settlement,
  onCancel,
}: {
  settlement: Settlement;
  onCancel: () => void;
}) {
  const [selectedType, setSelectedType] = useState<MembershipType>("SEMESTER");
  const [name, setName] = useState("");
  const membershipDetailsResult = useMembershipDetails();

  const membershipsResult = useMembershipsFor(settlement.id);

  const createMembership = useCreateMembershipMutation({
    onSuccess() {
      setName("");
    },
  });

  const destroyMembership = useDestroyMembership();

  const [age, setAge] = useState(18);

  const isSearching = name.trim().length > 0;
  const searchResults = useMembershipSearch(name, { enabled: isSearching });

  if (membershipDetailsResult.isLoading || membershipsResult.isLoading)
    return <div>Loading...</div>;

  const membershipDetails = membershipDetailsResult.data!;
  const memberships = (membershipsResult.data || [])
    .slice()
    .reverse()
    .filter((mem) => !mem.cancelledAt);

  const loading = createMembership.isLoading;
  const price =
    selectedType === "SEMESTER"
      ? membershipDetails.semesterPrice
      : membershipDetails.lifetimePrice;

  const membershipTypes: Array<{
    id: MembershipType;
    name: string;
    price: number;
  }> = [
    {
      id: "SEMESTER",
      name: membershipDetails.currentTerm,
      price: membershipDetails.semesterPrice,
    },
    {
      id: "LIFETIME",
      name: "Lifetime",
      price: membershipDetails.lifetimePrice,
    },
  ];

  const bannedPeople = searchResults.data?.bannedPeople || [];

  return (
    <div className="flex flex-col gap-4 md:flex-row md:items-start">
      <div>
        <Box
          title={`Register membership for ${settlement.name}`}
          className="flex-1"
          primary
          actions={
            <a
              href="#"
              onClick={(evt) => {
                evt.preventDefault();
                onCancel();
              }}
              className="text-secondary"
            >
              ← Go back
            </a>
          }
        >
          <div className="alert alert-info">
            <div className="flex">
              <AgeGuide
                age={age}
                onToggle={() => setAge((old) => (old === 18 ? 20 : 18))}
              />
            </div>
          </div>
          <div className="mt-4" />
          <form
            onSubmit={(evt) => {
              evt.preventDefault();
              createMembership.mutate({
                name,
                type: selectedType,
                settlementId: settlement.id,
              });
            }}
          >
            <div className="flex">
              {membershipTypes.map((type) => (
                <label key={type.id} className="flex items-center mr-8">
                  <input
                    type="radio"
                    className="radio"
                    checked={selectedType === type.id}
                    onChange={(evt) => {
                      if (evt.target.checked) {
                        setSelectedType(type.id);
                      }
                    }}
                  />
                  <span className="ml-2">{type.name}</span>
                </label>
              ))}
            </div>
            <div className="mt-4" />
            <div className="form-control">
              <label className="label">
                <span className="label-text">Price</span>
              </label>
              <div className="text-3xl font-bold">{price} NOK</div>
            </div>
            <div className="mt-4" />
            <Input
              id="membership-name"
              value={name}
              onChange={setName}
              label="Name"
            />
            <div className="mt-4" />
            <button
              className={classNames("btn btn-primary", { loading })}
              disabled={loading}
            >
              Submit
            </button>
          </form>
        </Box>
        {isSearching && (
          <>
            <div className="mt-4" />
            <Box
              title={`Search results: ${name}`}
              loading={searchResults.isLoading}
            >
              {bannedPeople.length > 0 && (
                <div className="alert alert-error max-w-prose mb-8">
                  <div>
                    <div className="prose text-inherit">
                      <p>
                        The name you entered matches a person which has been
                        banned:
                      </p>
                      <ul>
                        {bannedPeople.map((p) => (
                          <li key={p.id}>{p.name}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              <MembershipTable
                memberships={searchResults.data?.memberships || []}
              />
            </Box>
          </>
        )}
      </div>
      <Box title="Memberships registered so far" className="flex-1 min-w-0">
        <MembershipTable
          memberships={memberships}
          actions={(mem) => (
            <a
              href="#"
              className="link"
              onClick={(evt) => {
                evt.preventDefault();
                if (
                  window.confirm(
                    "Are you sure you want to remove this membership?"
                  )
                ) {
                  destroyMembership.mutate({
                    id: mem.id,
                    settlementId: settlement.id,
                  });
                }
              }}
            >
              Remove
            </a>
          )}
        />
      </Box>
    </div>
  );
}
