declare const RFI_ENV: Record<string, string | undefined> | undefined;

const env = typeof RFI_ENV === "object" ? RFI_ENV : {};

// Typically you'd use `import.meta.env` for configuring Vite.
// However, this one will be statically inlined during building.
// We'd like to be able to tweak these dynamcally. Therefore we've
// made a system where you can define a global RFI_ENV and they will
// be used instead.

/**
 * Helper method to retrieve strings from the environment.
 * Note that the first argument has to be a literal `import.meta.env.FOO`
 * For it to be optimized.
 */
function getString(
  fromMetaEnv: string | boolean | undefined,
  key: string
): string | undefined {
  const val = env[key];
  if (typeof val === "string") return val;
  if (typeof fromMetaEnv === "string") return fromMetaEnv;
}

/**
 * Helper method to retrieve flags from the environment.
 * Note that the first argument has to be a literal `import.meta.env.FOO`
 * for it to be optimized.
 */
function getBool(
  fromMetaEnv: string | boolean | undefined,
  key: string
): boolean {
  const val = env[key];
  if (val) return true;
  return !!fromMetaEnv;
}

export const SENTRY_DSN = getString(
  import.meta.env.RFI_SENTRY_DSN,
  "RFI_SENTRY_DSN"
);

export const API_URL = getString(import.meta.env.RFI_API_URL, "RFI_API_URL");

export const HIDE_EXPERIMENTAL = getBool(
  import.meta.env.RFI_HIDE_EXPERIMENTAL,
  "RFI_HIDE_EXPERIMENTAL"
);
