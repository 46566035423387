import { GraphQLClient } from "graphql-request";
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { createGraphQLClient } from "@/data";
import { Session } from "@/types";

type SessionManager = {
  client: GraphQLClient;
  useSession(session: Session, key: string): void;
  forgetSession(): void;
  currentSession: Session | null;
};

const currentVersion = 2;

const key = "rfiSessionData";

type SessionData = {
  version: number;
  session: Session;
  key: string;
};

const unauthenticatedClient = createGraphQLClient();

export const SessionContext = createContext<SessionManager | undefined>(
  undefined
);

export function SessionProvider({ children }: { children: ReactNode }) {
  const [sessionData, setSessionData] = useState(() => {
    const json = localStorage[key];
    if (json) {
      const data = JSON.parse(json) as SessionData;
      if (data.version !== currentVersion) return null;
      return data;
    }
    return null;
  });

  useEffect(() => {
    if (sessionData) {
      localStorage[key] = JSON.stringify(sessionData);
    } else {
      delete localStorage[key];
    }
  }, [sessionData]);

  const onDeauthenticate = useCallback(() => {
    delete localStorage[key];
    window.location.hash = "/";
    window.location.reload();
  }, []);

  const manager: SessionManager = useMemo(
    () => ({
      client: sessionData
        ? createGraphQLClient({ token: sessionData.key, onDeauthenticate })
        : unauthenticatedClient,
      currentSession: sessionData ? sessionData.session : null,
      useSession(session, key) {
        setSessionData({ session, key, version: currentVersion });
      },
      forgetSession() {
        setSessionData(null);
      },
    }),
    [sessionData, onDeauthenticate]
  );

  return (
    <SessionContext.Provider value={manager}>
      {children}
    </SessionContext.Provider>
  );
}
