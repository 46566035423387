import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { gql } from "graphql-request";

import { SessionContext, useRequiredContext } from "@/contexts";
import { BannedPerson } from "@/types";

const bannedPeopleQuery = gql`
  {
    bannedPeople {
      id
      name
      comment
      createdAt
    }
  }
`;

export function useBannedPeople(opts?: UseQueryOptions<BannedPerson[]>) {
  const client = useRequiredContext(SessionContext).client;

  return useQuery<BannedPerson[]>(
    ["bannedPeople"],
    () =>
      client
        .request<{ bannedPeople: BannedPerson[] }>(bannedPeopleQuery)
        .then((r) => r.bannedPeople),
    opts
  );
}

const addBannedPersonMutation = gql`
  mutation addBannedPerson($name: String!, $comment: String) {
    addBannedPerson(name: $name, comment: $comment) {
      bannedPerson {
        id
      }
    }
  }
`;

export function useAddBannedPerson() {
  const client = useRequiredContext(SessionContext).client;
  const queryClient = useQueryClient();

  return useMutation<string, unknown, { name: string; comment: string | null }>(
    (vars) =>
      client
        .request<{ addBannedPerson: { bannedPerson: { id: string } } }>(
          addBannedPersonMutation,
          vars
        )
        .then((r) => r.addBannedPerson.bannedPerson.id),
    {
      onSuccess() {
        queryClient.invalidateQueries(["bannedPeople"]);
      },
    }
  );
}

const removeBannedPersonMutation = gql`
  mutation removeBannedPerson($id: String!) {
    removeBannedPerson(id: $id) {
      id
    }
  }
`;

export function useRemoveBannedPerson() {
  const client = useRequiredContext(SessionContext).client;
  const queryClient = useQueryClient();

  return useMutation<string, unknown, string>(
    (id) =>
      client
        .request<{ removeBannedPerson: { id: string } }>(
          removeBannedPersonMutation,
          { id }
        )
        .then((r) => r.removeBannedPerson.id),
    {
      onSuccess() {
        queryClient.invalidateQueries(["bannedPeople"]);
      },
    }
  );
}
