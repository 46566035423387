import { CHANGELOG } from "@/data";
import { Box } from "@/ui";
import { formatDate } from "@/utils";

export function ChangelogPage() {
  return (
    <Box title="Changelog">
      <div className="prose">
        <ul>
          {CHANGELOG.map((entry, idx) => (
            <li key={idx}>
              <strong>{formatDate(new Date(entry.date))}:</strong> {entry.title}
            </li>
          ))}
        </ul>
      </div>
    </Box>
  );
}
