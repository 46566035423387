import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import classNames from "classnames";
import { useMemo } from "react";

import { useBreakpoint } from "use-breakpoint";

import {
  SessionContext,
  SessionProvider,
  ThemeProvider,
  useRequiredContext,
} from "@/contexts";
import { ThemePicker, ErrorBoundary } from "@/controllers";
import { useDeauthenticateMutate, useOpenSettlements } from "@/data";
import { NotFoundPage } from "@/pages";
import { Box, Crumb, Header, MenuCrumbs, MenuSection } from "@/ui";
import { useHashLocation } from "@/utils";

import { HIDE_EXPERIMENTAL } from "./env";
import { buildMenu } from "./menu";
import { router } from "./routes";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      useErrorBoundary: true,
      networkMode: "always",
    },
    mutations: {
      useErrorBoundary: true,
      networkMode: "always",
    },
  },
});

function useRoute() {
  const loc = useHashLocation();
  const route = useMemo(() => {
    try {
      return router.resolve(loc);
    } catch (err) {
      if (err instanceof Error && err.message === "Route not found") {
        return null;
      }
      throw err;
    }
  }, [loc]);
  return route;
}

const MENU = { hide: 0, show: 1024 };

function Main() {
  const route = useRoute();
  const session = useRequiredContext(SessionContext);

  // We can only fetch this when we're authenticated
  const openSettlements = useOpenSettlements({
    enabled: session.currentSession !== null,
  });

  const menu = useMemo(
    () =>
      buildMenu({
        openSettlementCount:
          (session.currentSession !== null && openSettlements.data?.length) ||
          null,
        includeExperimental: !HIDE_EXPERIMENTAL,
      }),
    [openSettlements.data, session.currentSession]
  );
  const { breakpoint: menuVisibility } = useBreakpoint(MENU, "hide");
  const deauth = useDeauthenticateMutate();

  const crumbs: null | Crumb[] = useMemo(() => {
    if (!route) return null;

    const crumbs: Crumb[] = [{ title: "Home", to: "/" }];
    const menuItem = route.menuItem;

    if (menuItem) {
      const sec = menu.find((s) => s.id === menuItem[0]);
      if (!sec) return null;
      const item = sec.children.find((i) => i.id === menuItem[1]);
      if (!item) return null;
      crumbs.push({ title: sec.title }, { title: item.title, to: item.to });
    } else if (route.crumbTitle) {
      crumbs.push({ title: route.crumbTitle });
    }

    return crumbs;
  }, [route, menu]);

  const middle =
    menuVisibility === "show" &&
    menu.map((section, idx) => (
      <div
        key={section.id}
        className={classNames("px-8 border-base-300", { "border-l": idx > 0 })}
      >
        <MenuSection section={section} />
      </div>
    ));

  const mainContent = () => {
    if (!route) return <NotFoundPage />;

    if (route.isRoot && menuVisibility === "hide") {
      // This a bit hacky, but we want to show the menu here instead:
      return (
        <>
          {route.node}
          <div className="mt-4" />
          <Box title="Menu">
            {menu.map((section) => (
              <div key={section.id} className="mb-4">
                <MenuSection section={section} />
              </div>
            ))}
          </Box>
        </>
      );
    }

    return route.node;
  };

  return (
    <div>
      <Header
        middle={middle}
        right={
          <div className="flex flex-col items-end">
            <ThemePicker />

            {session.currentSession && (
              <div className="text-sm mt-2">
                Logged in as {session.currentSession.name}.{" "}
                <button
                  onClick={(evt) => {
                    evt.preventDefault();
                    deauth.mutate(undefined, {
                      onSuccess() {
                        session.forgetSession();
                        window.location.hash = "#/";
                      },
                    });
                  }}
                  className={classNames(
                    "inline text-primary underline hover:no-underline",
                    { loading: deauth.isLoading }
                  )}
                >
                  Log out.
                </button>
              </div>
            )}
          </div>
        }
      />
      <div className="mt-4" />
      {crumbs && (
        <div className="container mx-auto mt-4 px-4">
          <MenuCrumbs crumbs={crumbs} />
        </div>
      )}

      <div className="container mx-auto">{mainContent()}</div>
    </div>
  );
}

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <ThemeProvider>
          <SessionProvider>
            <Main />
          </SessionProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </QueryClientProvider>
  );
}

export function buildApp() {
  return <App />;
}
