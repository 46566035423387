import type { MenuSection as TMenuSection } from "@/types";
import { Link } from "@/ui";

/** MenuSection renders the content of a single section. */
export function MenuSection({ section }: { section: TMenuSection }) {
  return (
    <>
      <div className="font-semibold">{section.title}</div>
      <div>
        {section.children.map((item) => (
          <Link
            key={item.id}
            to={item.to}
            className="block text-primary hover:underline text-sm mt-1"
          >
            {item.title}
          </Link>
        ))}
      </div>
    </>
  );
}
