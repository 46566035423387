import { useEffect, useState } from "react";

const DATETIME_FORMAT = new Intl.DateTimeFormat("no", {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
});

function calculateBirthDate(age: number) {
  const birthDate = new Date();
  birthDate.setFullYear(birthDate.getFullYear() - age);
  return DATETIME_FORMAT.format(birthDate);
}

/**
 * Shows the birth date for people who are `age` years.
 */
export function AgeGuide({
  age,
  onToggle,
}: {
  age: number;
  onToggle: () => void;
}) {
  const [birthDate, setBirthDate] = useState(() => calculateBirthDate(age));

  useEffect(() => {
    // Recalculate when age changes
    setBirthDate(calculateBirthDate(age));

    // Recalculate every 5th second
    const id = setInterval(() => {
      setBirthDate(calculateBirthDate(age));
    }, 5000);
    return () => clearInterval(id);
  }, [age]);

  return (
    <div className="flex">
      <div>
        People born on <strong>{birthDate}</strong> or earlier are{" "}
        <a
          href="#"
          className="underline"
          onClick={(evt) => {
            evt.preventDefault();
            onToggle();
          }}
        >
          {age}
        </a>{" "}
        years old or older.
      </div>
    </div>
  );
}
