const DATE_FORMAT = new Intl.DateTimeFormat(undefined, {
  dateStyle: "medium",
});

const DATETIME_FORMAT = new Intl.DateTimeFormat(undefined, {
  dateStyle: "medium",
  timeStyle: "short",
});

export function formatDate(date: Date | number) {
  return DATE_FORMAT.format(date);
}

export function formatDateTime(date: Date | number) {
  return DATETIME_FORMAT.format(date);
}
