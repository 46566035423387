import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { gql } from "graphql-request";

import { SessionContext, useRequiredContext } from "@/contexts";
import { ProductMenu } from "@/types";

const productMenuQuery = gql`
  {
    productMenu {
      sheetId
      url
    }
  }
`;

export function useProductMenu(
  opts?: UseQueryOptions<ProductMenu | undefined>
) {
  const client = useRequiredContext(SessionContext).client;

  return useQuery<ProductMenu | undefined>(
    ["productMenu.current"],
    () =>
      client
        .request<{ productMenu?: ProductMenu }>(productMenuQuery)
        .then((r) => r.productMenu),
    opts
  );
}

const updateProductMenuQuery = gql`
  mutation updateProductMenu($sheetId: String!) {
    updateProductMenu(sheetId: $sheetId) {
      productMenu {
        sheetId
        url
      }
    }
  }
`;

export function useUpdateProductMenu(
  opts?: UseMutationOptions<ProductMenu, unknown, { sheetId: string }>
) {
  const client = useRequiredContext(SessionContext).client;
  const queryClient = useQueryClient();

  return useMutation(
    (vars: { sheetId: string }) =>
      client
        .request<{
          updateProductMenu: { productMenu: ProductMenu };
        }>(updateProductMenuQuery, vars)
        .then((r) => r.updateProductMenu.productMenu),
    {
      ...opts,
      onSuccess(data, vars, ctx) {
        queryClient.setQueryData(["productMenu.current"], data);
        if (opts?.onSuccess) opts.onSuccess(data, vars, ctx);
      },
    }
  );
}
