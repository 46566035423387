import { useSettlements } from "@/data";
import { Box, SettlementTable } from "@/ui";
import { replaceHashParams, useHashParams } from "@/utils";

export function ListSettlementsPage() {
  const params = useHashParams();
  const includeClosed = !params.has("open");

  const result = useSettlements({ type: includeClosed ? "all" : "open" });

  return (
    <Box title="Settlements" primary loading={result.isLoading}>
      <div className="flex">
        <label className="mr-8 label cursor-pointer">
          <span className="mr-2">Include closed settlements</span>
          <input
            type="checkbox"
            checked={includeClosed}
            className="checkbox"
            onChange={(evt) => {
              replaceHashParams({ open: evt.target.checked ? undefined : "1" });
            }}
          />
        </label>
      </div>
      <SettlementTable
        settlements={result.data || []}
        showClosedAt={includeClosed}
      />
    </Box>
  );
}
