import classNames from "classnames";

import { Membership } from "@/types";
import { formatDateTime, formatNOK } from "@/utils";

export function MembershipTable({
  memberships,
  actions,
}: {
  memberships: Membership[];
  actions?: (mem: Membership) => JSX.Element;
}) {
  const activeMemberships = memberships.filter((mem) => !mem.cancelledAt);

  return (
    <table className="table table-zebra w-full">
      <thead>
        <tr>
          <th>Code</th>
          <th>Name</th>
          <th>Price</th>
          <th>Registered at</th>
          {actions && <th></th>}
        </tr>
      </thead>
      <tbody>
        {memberships.length === 0 && (
          <tr>
            <td colSpan={4} className="px-8 py-8 italic">
              There's no memberships to show.
            </td>
          </tr>
        )}
        {memberships.map((mem) => (
          <tr key={mem.id}>
            <td className="whitespace-normal">{mem.code}</td>
            <td
              className={classNames("whitespace-normal", {
                "line-through": mem.cancelledAt,
              })}
            >
              {mem.name}
            </td>
            <td
              className={classNames({
                "line-through": mem.cancelledAt,
              })}
            >
              {formatNOK(mem.price)}
            </td>
            <td>{formatDateTime(new Date(mem.createdAt))}</td>
            {actions && <td>{actions(mem)}</td>}
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th />
          <th>Count: {activeMemberships.length}</th>
          <th>
            Sum:{" "}
            {formatNOK(
              activeMemberships.reduce((sum, mem) => sum + mem.price, 0)
            )}
          </th>
          <th></th>
          {actions && <th></th>}
        </tr>
      </tfoot>
    </table>
  );
}
