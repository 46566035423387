import { ReactNode } from "react";

import { SessionContext, useRequiredContext } from "@/contexts";
import { handleError, useAuthenticateMutate } from "@/data";
import { Box, LoginBox, LoginBoxErrors } from "@/ui";

type Permission = "register" | "settlements" | "reports" | "admin";

const REQUIRED_ROLE: Record<Permission, string> = {
  register: "barfunk",
  settlements: "sm",
  reports: "admin",
  admin: "admin",
};

/** Returns the primary permissions for a role. */
function mainPermissionsFor(role: string): Permission[] {
  return Object.entries(REQUIRED_ROLE)
    .filter(([_, requiredRole]) => requiredRole === role)
    .map(([perm]) => perm as Permission);
}

const MAPPINGS: Record<string, Set<Permission>> = {};

MAPPINGS.barfunk = new Set(mainPermissionsFor("barfunk"));
MAPPINGS.sm = new Set([...mainPermissionsFor("sm"), ...MAPPINGS.barfunk]);
MAPPINGS.admin = new Set([...mainPermissionsFor("admin"), ...MAPPINGS.sm]);

function can(roleId: string, permission: Permission) {
  if (roleId === "superadmin") return true;
  const mapping = MAPPINGS[roleId];
  if (mapping) return mapping.has(permission);
  return false;
}

export function Authorization({
  children,
  permission,
}: {
  permission: Permission;
  children: ReactNode;
}): JSX.Element {
  const session = useRequiredContext(SessionContext);

  if (!session.currentSession) {
    return <Login defaultRole={REQUIRED_ROLE[permission]} />;
  }

  if (!can(session.currentSession.roleId, permission)) {
    return (
      <Box title="Access denied" primary>
        <div className="alert alert-warning">
          You don't have access to this page.
        </div>
      </Box>
    );
  }

  return <>{children}</>;
}

function Login({ defaultRole }: { defaultRole: string }) {
  const session = useRequiredContext(SessionContext);
  const auth = useAuthenticateMutate({
    onSuccess(data) {
      session.useSession(data.session, data.key);
    },
  });

  const errors = auth.isError
    ? handleError<LoginBoxErrors>(auth.error, {
        password: () => ({ password: "Incorrect password" }),
        nameRequired: () => ({ name: "Field is required" }),
      })
    : undefined;

  return (
    <Box title="Log in">
      <div className="alert alert-info">
        You need to log in to use this functionality.
      </div>
      <div className="mt-4" />
      <LoginBox
        defaultRole={defaultRole}
        errors={errors}
        loading={auth.isLoading}
        onSubmit={(vars) => {
          auth.mutate(vars);
        }}
      />
    </Box>
  );
}
