import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { createRoot } from "react-dom/client";

import { buildApp } from "./App";
import { SENTRY_DSN } from "./env";

import "./index.css";

if (typeof SENTRY_DSN === "string") {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: import.meta.env.MODE,
  });
}

function main() {
  const dom = document.querySelector("#app");
  if (!dom) throw new Error("unable to find #app");

  const root = createRoot(dom);
  root.render(buildApp());
}

main();
