import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { gql } from "graphql-request";

import { SessionContext, useRequiredContext } from "@/contexts";
import { Session } from "@/types";

const mutation = gql`
  mutation authenticate($name: String!, $roleId: String!, $password: String!) {
    authenticate(name: $name, roleId: $roleId, password: $password) {
      key
      session {
        id
        name
        roleId
      }
    }
  }
`;

export type AuthenticateParams = {
  name: string;
  roleId: string;
  password: string;
};

export type AuthenticateData = {
  session: Session;
  key: string;
};

export function useAuthenticateMutate(
  opts?: UseMutationOptions<AuthenticateData, unknown, AuthenticateParams>
) {
  const client = useRequiredContext(SessionContext).client;

  return useMutation(
    (vars: AuthenticateParams) =>
      client
        .request<{
          authenticate: AuthenticateData;
        }>(mutation, vars)
        .then((r) => r.authenticate),
    opts
  );
}

const deauthicationMutation = gql`
  mutation deauthenticate {
    deauthenticate {
      session {
        id
      }
    }
  }
`;

export function useDeauthenticateMutate() {
  const client = useRequiredContext(SessionContext).client;

  return useMutation(() =>
    client
      .request<{
        deauthenticate: { session: { id: string } };
      }>(deauthicationMutation)
      .then((r) => r.deauthenticate)
  );
}
