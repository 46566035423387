import classNames from "classnames";

import { useCloseSettlementMutation, useSettlement } from "@/data";
import { Box, MembershipTable } from "@/ui";
import { formatDateTime, formatNOK } from "@/utils";

export function SettlementPage({
  id,
  showCloseState = true,
}: {
  id: string;

  /**
   * When false, this doesn't show the "closed" fields. This is useful
   * if you're rendering this page in a context where you know the settlement
   * is open.
   */
  showCloseState?: boolean;
}) {
  const close = useCloseSettlementMutation();
  const result = useSettlement(id);
  if (result.isLoading) return <Box primary title="Settlement" loading />;

  const settlement = result.data!;

  return (
    <Box primary title={`Settlement: ${settlement.name}`}>
      <div className="alert alert-info max-w-prose">
        {settlement.closedAt ? (
          <div>
            <div className="prose">
              <p>
                This settlement is currently <strong>closed</strong>.
              </p>
            </div>
          </div>
        ) : (
          <div>
            <div className="prose">
              <p>
                This settlement is currently <strong>open</strong>. This means
                that it's possible to register more memberships to it. Once
                you're done accepting memberships you should, take a Z-report
                from the card terminals, count the money, and{" "}
                <strong>close this settlment</strong>. The sum in{" "}
                <strong>"Registered memberships"</strong> below should be
                entered into the "oppgjørsskjema".
              </p>
              <p>
                Any mistakes in registering memberships should be fixed{" "}
                <em>before</em> closing the settlement.
              </p>
              <button
                className={classNames("btn btn-primary", {
                  loading: close.isLoading,
                })}
                onClick={(evt) => {
                  evt.preventDefault();
                  close.mutate({ id });
                }}
              >
                Close settlement
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="mt-4" />

      <table className="table table-zebra">
        <tbody>
          <tr>
            <th>Created by</th>
            <td>{settlement.creator.name}</td>
          </tr>
          <tr>
            <th>Created at</th>
            <td>{formatDateTime(new Date(settlement.createdAt))}</td>
          </tr>
          {showCloseState && (
            <>
              <tr>
                <th>Closed by</th>
                <td>{settlement.closedBy ? settlement.closedBy.name : "-"}</td>
              </tr>
              <tr>
                <th>Closed at</th>
                <td>
                  {settlement.closedAt
                    ? formatDateTime(new Date(settlement.closedAt))
                    : "-"}
                </td>
              </tr>
            </>
          )}
          <tr>
            <th>Registered memberships</th>
            <td>{settlement.totalCount}</td>
          </tr>
          <tr>
            <th>Registered memberships</th>
            <td>{formatNOK(settlement.totalPrice)}</td>
          </tr>
        </tbody>
      </table>

      <div className="mt-8" />

      {settlement.memberships && (
        <MembershipTable memberships={settlement.memberships} />
      )}
    </Box>
  );
}
