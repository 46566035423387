import { useEffect, useState } from "react";

const currentLocation = () => {
  return (window.location.hash.slice(1) || "/").replace(/\?.*$/, "");
};

export const useHashLocation = () => {
  const [loc, setLoc] = useState(currentLocation());

  useEffect(() => {
    // this function is called whenever the hash changes
    const handler = () => setLoc(currentLocation());

    // subscribe to hash changes
    window.addEventListener("hashchange", handler);
    return () => window.removeEventListener("hashchange", handler);
  }, []);

  return loc;
};

const currentParams = () => {
  const hash = window.location.hash;
  const pos = hash.indexOf("?");
  if (pos >= 0) {
    return new URLSearchParams(hash.slice(pos));
  } else {
    return new URLSearchParams();
  }
};

/**
 * Listens to the parameters stored in the hash.
 */
export const useHashParams = () => {
  const [params, setParams] = useState<URLSearchParams>(() => currentParams());

  useEffect(() => {
    // this function is called whenever the hash changes
    const handler = () => setParams(currentParams());

    // subscribe to hash changes
    window.addEventListener("hashchange", handler);
    return () => window.removeEventListener("hashchange", handler);
  }, []);

  return params;
};

/**
 * Updates the params set in the hash. `undefined` means "remove".
 */
export const replaceHashParams = (
  params: Record<string, string | undefined>
) => {
  const current = currentParams();
  for (const [name, value] of Object.entries(params)) {
    if (value === undefined) {
      current.delete(name);
    } else {
      current.set(name, value);
    }
  }

  const str = current.toString();

  let hash = window.location.hash;
  const pos = hash.indexOf("?");
  if (pos >= 0) {
    hash = hash.slice(0, pos);
  }
  if (str) {
    hash += "?" + str;
  }

  window.history.replaceState(null, "", hash);
  // Manually trigger a hash change as replaceState doesn't do that.
  window.dispatchEvent(new HashChangeEvent("hashchange"));
};
