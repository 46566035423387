import { useEffect } from "react";

export function PageTitle({ text }: { text: string }) {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = text;
    return () => {
      document.title = prevTitle;
    };
  }, [text]);

  return <h1 className="title">{text}</h1>;
}
