import { Box, Link } from "@/ui";

export function NotFoundPage() {
  return (
    <Box title="Page not found 💩" primary>
      <p>
        Go{" "}
        <Link to="/" className="link">
          back
        </Link>{" "}
        and try again?
      </p>
    </Box>
  );
}
