import classNames from "classnames";

import { ThemeContext, useRequiredContext } from "@/contexts";

export function ThemePicker() {
  const { availableThemes, currentTheme, changeTheme } =
    useRequiredContext(ThemeContext);

  return (
    <div className="dropdown dropdown-end">
      <div
        tabIndex={0}
        className="btn btn-sm btn-secondary btn-outline normal-case"
      >
        Change theme
      </div>
      <ul
        tabIndex={0}
        className="p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52 overflow-auto border border-secondary"
        style={{ maxHeight: 430 }}
      >
        {availableThemes.map((theme) => (
          <li key={theme.id}>
            <a
              className={classNames({ active: theme.id === currentTheme })}
              onClick={(evt) => {
                evt.preventDefault();
                changeTheme(theme.id);
              }}
            >
              {theme.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
