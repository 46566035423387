import type { ReactNode } from "react";

import { Link } from "@/ui";

export function Header({
  middle,
  right,
}: {
  middle?: ReactNode;
  right?: ReactNode;
}) {
  return (
    <div className="box mt-2">
      <div className="flex">
        <Link
          to="/"
          className="block text-3xl bg-primary text-primary-content px-4 py-2 font-bold self-start"
        >
          RFI
        </Link>

        {middle}
        <div className="flex-1" />
        {right}
      </div>
    </div>
  );
}
