import { useEffect } from "react";

export function usePageTitle(text: string | undefined) {
  useEffect(() => {
    const prevTitle = document.title;
    if (text) document.title = text;
    return () => {
      document.title = prevTitle;
    };
  }, [text]);
}
