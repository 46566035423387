import classNames from "classnames";
import { useCallback, useState } from "react";

import {
  handleError,
  useCreateSettlementMutation,
  useMembershipDetails,
} from "@/data";
import { MembershipDetails } from "@/types";
import { Box, Input } from "@/ui";

type TemplateType = "friday" | "wristband" | "blank";

const TEMPLATES: Array<{ type: TemplateType; label: string }> = [
  { type: "friday", label: "Friday pub" },
  { type: "wristband", label: "Wristband" },
  { type: "blank", label: "Blank" },
];

const FRIDAY = 5;
const DATETIME_FORMAT = new Intl.DateTimeFormat("en", {
  day: "2-digit",
  month: "long",
  year: "numeric",
});

function nameFor(
  template: TemplateType,
  memDetails: MembershipDetails | undefined
): string {
  if (template === "friday") {
    const today = new Date();
    const extraDays = (FRIDAY - today.getDay() + 7) % 7;
    today.setDate(today.getDate() + extraDays);
    return `Friday pub (${DATETIME_FORMAT.format(today)})`;
  } else if (template === "wristband") {
    if (memDetails) {
      return `Wristband ${memDetails.currentTerm}`;
    } else {
      return `Wristband`;
    }
  } else {
    return "";
  }
}

export function SettlementsNewPage() {
  const memDetails = useMembershipDetails();
  const [template, setTemplate] = useState<TemplateType>("friday");
  const [name, setName] = useState(() => nameFor(template, undefined));
  const mut = useCreateSettlementMutation({
    onSuccess() {
      window.location.hash = `#/memberships/register`;
    },
  });

  const errors: { name?: string } = mut.isError
    ? handleError(mut.error, {
        nameRequired: () => ({ name: "Field is required" }),
      })
    : {};

  const updateTemplate = useCallback(
    (tmpl: TemplateType) => {
      setTemplate(tmpl);
      setName(nameFor(tmpl, memDetails.data));
    },
    [memDetails.data]
  );

  return (
    <Box title="New settlement" primary loading={memDetails.isLoading}>
      <div className="max-w-prose">
        <form
          onSubmit={(evt) => {
            evt.preventDefault();
            mut.mutate({ name });
          }}
        >
          <div className="form-control">
            <label className="label">
              <span className="label-text">Template</span>
            </label>
            <div className="flex">
              {TEMPLATES.map((tmpl) => (
                <label key={tmpl.type} className="flex items-center mr-8">
                  <input
                    type="radio"
                    className="radio"
                    checked={template === tmpl.type}
                    onChange={(evt) => {
                      if (evt.target.checked) {
                        updateTemplate(tmpl.type);
                      }
                    }}
                  />
                  <span className="ml-2">{tmpl.label}</span>
                </label>
              ))}
            </div>
          </div>
          <Input
            id="login-name"
            label="Name"
            value={name}
            onChange={setName}
            error={errors.name}
          />
          <div className="mt-4" />
          <button
            className={classNames("btn btn-primary", {
              loading: mut.isLoading,
            })}
          >
            Create
          </button>
        </form>
      </div>
    </Box>
  );
}
