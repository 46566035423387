import classNames from "classnames";
import { useState } from "react";

import {
  useAddBannedPerson,
  useBannedPeople,
  useRemoveBannedPerson,
} from "@/data";
import { Box, Input } from "@/ui";

export function BannedPeoplePage() {
  return (
    <div>
      <ListBannedPeople />
      <div className="mb-8" />
      <AddBannedPerson />
    </div>
  );
}

function ListBannedPeople() {
  const result = useBannedPeople();
  const mut = useRemoveBannedPerson();

  return (
    <Box
      title="Banned people"
      primary
      loading={result.isLoading || mut.isLoading}
    >
      <div className="alert alert-info max-w-prose">
        <div>
          <p>
            People on this list will show up in the search results when adding a
            new membership. The "admin comment" is only available on{" "}
            <em>this</em> page and will not be shown to the Barfunk.
          </p>
        </div>
      </div>

      <div className="mt-4" />

      <table className="table table-zebra w-full">
        <thead>
          <tr>
            <th>Name</th>
            <th>Admin comment</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {result.data?.length === 0 && (
            <tr>
              <td colSpan={3} className="px-8 py-8 italic">
                There's no banned people.
              </td>
            </tr>
          )}
          {result.data?.map((p) => (
            <tr key={p.id}>
              <td>{p.name}</td>
              <td>{p.comment}</td>
              <td>
                <a
                  className="link"
                  href="#"
                  onClick={(evt) => {
                    evt.preventDefault();
                    mut.mutate(p.id);
                  }}
                >
                  Remove
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
}

function AddBannedPerson() {
  const mut = useAddBannedPerson();
  const [name, setName] = useState("");
  const [comment, setComment] = useState("");

  const loading = mut.isLoading;

  return (
    <Box title="Add new banned person">
      <form
        className="max-w-prose"
        onSubmit={(evt) => {
          evt.preventDefault();
          mut.mutate(
            { name, comment },
            {
              onSuccess() {
                setName("");
                setComment("");
              },
            }
          );
        }}
      >
        <Input id="name" value={name} label="Name" onChange={setName} />
        <Input
          id="comment"
          value={comment}
          label="Comment"
          onChange={setComment}
        />
        <div className="mt-2" />
        <button
          className={classNames("btn btn-primary", { loading })}
          disabled={loading}
        >
          Save
        </button>
      </form>
    </Box>
  );
}
