import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { gql } from "graphql-request";

import { SessionContext, useRequiredContext } from "@/contexts";
import {
  BannedPerson,
  Membership,
  MembershipDetails,
  MembershipType,
} from "@/types";

const membershipDetailsQuery = gql`
  {
    membershipDetails {
      currentTerm
      semesterPrice
      lifetimePrice
    }
  }
`;

export function useMembershipDetails() {
  const client = useRequiredContext(SessionContext).client;

  return useQuery<MembershipDetails>(["memberships.details"], () =>
    client
      .request<{ membershipDetails: MembershipDetails }>(membershipDetailsQuery)
      .then((r) => r.membershipDetails)
  );
}

const activeMembershipQuery = gql`
  query ($type: MembershipType, $before: ISO8601Date) {
    listActiveMemberships(membershipType: $type, beforeDate: $before) {
      id
      name
      code
      price
      createdAt
      cancelledAt
    }
  }
`;

export function useActiveMembershipQuery({
  type,
  before,
}: {
  type?: MembershipType;
  before?: string;
} = {}) {
  const client = useRequiredContext(SessionContext).client;

  return useQuery<Membership[]>(["memberships.active", before, type], () =>
    client
      .request<{ listActiveMemberships: Membership[] }>(activeMembershipQuery, {
        type,
        before,
      })
      .then((r) => r.listActiveMemberships)
  );
}

const createMembershipMutation = gql`
  mutation createMembership(
    $name: String!
    $type: MembershipType!
    $settlementId: String!
  ) {
    createMembership(name: $name, type: $type, settlementId: $settlementId) {
      membership {
        id
        name
        code
        price
        createdAt
        cancelledAt
      }
    }
  }
`;

type CreateMembershipVars = {
  name: string;
  type: MembershipType;
  settlementId: string;
};

export function useCreateMembershipMutation({
  onSuccess,
  ...opts
}: UseMutationOptions<Membership, unknown, CreateMembershipVars> = {}) {
  const client = useRequiredContext(SessionContext).client;
  const queryClient = useQueryClient();

  return useMutation(
    (vars: { name: string }) =>
      client
        .request<{
          createMembership: { membership: Membership };
        }>(createMembershipMutation, vars)
        .then((r) => r.createMembership.membership),
    {
      onSuccess(data, vars, context) {
        queryClient.invalidateQueries(["membershipsFor", vars.settlementId]);
        if (onSuccess) onSuccess(data, vars, context);
      },
      ...opts,
    }
  );
}

const membershipsForQuery = gql`
  query ($settlementId: String!) {
    membershipsFor(settlementId: $settlementId) {
      id
      code
      name
      price
      createdAt
      cancelledAt
    }
  }
`;

export function useMembershipsFor(settlementId: string) {
  const client = useRequiredContext(SessionContext).client;

  return useQuery(["membershipsFor", settlementId], () =>
    client
      .request<{ membershipsFor: Membership[] | null }>(membershipsForQuery, {
        settlementId,
      })
      .then((r) => r.membershipsFor)
  );
}

const membershipSearchQuery = gql`
  query ($query: String!) {
    searchName(query: $query, limit: 5) {
      memberships {
        id
        code
        name
        price
        createdAt
        cancelledAt
      }
      bannedPeople {
        id
        name
      }
    }
  }
`;

type SearchResult = {
  memberships: Membership[];
  bannedPeople: Pick<BannedPerson, "id" | "name">[];
};

export function useMembershipSearch(
  query: string,
  opts?: UseQueryOptions<SearchResult>
) {
  const client = useRequiredContext(SessionContext).client;

  return useQuery<SearchResult>(
    ["membershipSearch", query],
    () =>
      client
        .request<{ searchName: SearchResult }>(membershipSearchQuery, {
          query,
        })
        .then((r) => r.searchName),
    opts
  );
}

const destroyMembershipMutation = gql`
  mutation destroyMembership($id: String!) {
    destroyMembership(id: $id) {
      id
    }
  }
`;

export function useDestroyMembership() {
  const client = useRequiredContext(SessionContext).client;
  const queryClient = useQueryClient();

  return useMutation(
    (vars: { id: string; settlementId: string }) =>
      client
        .request<{
          destroyMembership: { id: string };
        }>(destroyMembershipMutation, { id: vars.id })
        .then((r) => r.destroyMembership.id),
    {
      onSuccess(data, vars) {
        queryClient.invalidateQueries(["membershipsFor", vars.settlementId]);
      },
    }
  );
}
