import { CHANGELOG } from "@/data";
import { Link, Box } from "@/ui";
import { formatDate } from "@/utils";

export function RootPage() {
  return (
    <>
      <Box title="Welcome to RFI!" primary>
        <div className="prose">
          <p>
            <strong>RFI</strong> is an internal tool for{" "}
            <a href="http://realistforeningen.no/">Realistforeningen</a>, a
            student society at University of Oslo. This site is currently under
            active development.
          </p>
        </div>
      </Box>
      <div className="mt-4" />
      <Box title="Latest changes">
        <div className="prose">
          <ul>
            {CHANGELOG.slice(0, 3).map((entry, idx) => (
              <li key={idx}>
                <strong>{formatDate(new Date(entry.date))}:</strong>{" "}
                {entry.title}
              </li>
            ))}
          </ul>
          <Link to="/changelog" className="hover:no-underline">
            See all changes…
          </Link>
        </div>
      </Box>
    </>
  );
}
