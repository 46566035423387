import classNames from "classnames";
import { useState } from "react";

import { CustomError, useProductMenu, useUpdateProductMenu } from "@/data";
import { Box, Input } from "@/ui";

export function EditProductMenuPage() {
  // We use this as a way of "clearing" the state of the form whenever we succeed with a request.
  const [key, setKey] = useState(0);
  const productMenuResult = useProductMenu();
  const mut = useUpdateProductMenu({
    onSuccess: () => {
      setKey((k) => k + 1);
    },
  });

  if (productMenuResult.isLoading) return <></>;

  const productMenu = productMenuResult.data;

  return (
    <Box title="Product menu">
      <div className="max-w-prose">
        {mut.isError && (
          <div className="alert alert-error mb-4">
            <p>
              Failed to save menu: <strong>{parseError(mut.error)}</strong>
            </p>
          </div>
        )}

        {productMenu && (
          <div className="alert alert-info mb-2">
            <p>
              The menu is available at
              <strong>
                <a
                  href={productMenu.url}
                  className="underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  {productMenu.url}
                </a>
                .
              </strong>
            </p>
          </div>
        )}
        <ProductMenuForm
          key={key}
          loading={mut.isLoading}
          initialSheetId={productMenu?.sheetId}
          onSubmit={(vars) => {
            mut.mutate(vars);
          }}
        />
      </div>
    </Box>
  );
}

function parseError(err: unknown) {
  if (err instanceof CustomError) {
    return err.message;
  } else {
    return "Unknown error.";
  }
}

const URL_RE = /https:\/\/docs\.google\.com\/spreadsheets\/d\/(.*?)\/edit/;

function ProductMenuForm({
  initialSheetId,
  onSubmit,
  loading = false,
}: {
  initialSheetId?: string;
  loading?: boolean;
  onSubmit: (values: { sheetId: string }) => void;
}) {
  const [sheetId, setSheetId] = useState(initialSheetId || "");

  return (
    <form
      onSubmit={(evt) => {
        evt.preventDefault();
        const m = sheetId.match(URL_RE);
        if (m) {
          onSubmit({ sheetId: m[1] });
        } else {
          onSubmit({ sheetId });
        }
      }}
    >
      <Input
        id="sheet"
        value={sheetId}
        label="Sheet ID (or URL)"
        onChange={setSheetId}
      />
      <div className="mt-2" />
      <button
        className={classNames("btn btn-primary", { loading })}
        disabled={loading}
      >
        Save
      </button>
    </form>
  );
}
