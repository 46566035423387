import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { gql } from "graphql-request";

import { SessionContext, useRequiredContext } from "@/contexts";
import { Settlement, SettlementWithMemberships } from "@/types";

const fields = `
  id
  name
  creator {
    id
    name
    roleId
  }
  createdAt
  closedAt
  closedBy {
    id
    name
    roleId
  }
  totalCount
  totalPrice
`;

const openSettlementQuery = gql`{ openSettlements { ${fields} } }`;
const listSettlementQuery = gql` { listSettlements { ${fields} } }`;
const settlementQuery = gql`
query ($settlementId: String!) {
  settlement(id: $settlementId) {
    ${fields}
    memberships {
      id
      code
      name
      price
      createdAt
      cancelledAt
    }
  }
}`;

export function useOpenSettlements(opts?: UseQueryOptions<Settlement[]>) {
  return useSettlements({ type: "open", ...opts });
}

export function useSettlements({
  type,
  ...opts
}: UseQueryOptions<Settlement[]> & { type: "all" | "open" }) {
  const client = useRequiredContext(SessionContext).client;

  return useQuery<Settlement[]>(
    ["settlements", type],
    () =>
      type === "all"
        ? client
            .request<{ listSettlements: Settlement[] }>(listSettlementQuery)
            .then((r) => r.listSettlements)
        : client
            .request<{ openSettlements: Settlement[] }>(openSettlementQuery)
            .then((r) => r.openSettlements),
    opts
  );
}

export function useSettlement(
  id: string,
  opts?: UseQueryOptions<SettlementWithMemberships>
) {
  const client = useRequiredContext(SessionContext).client;

  return useQuery<SettlementWithMemberships>(
    ["settlement", id],
    () =>
      client
        .request<{ settlement: SettlementWithMemberships }>(settlementQuery, {
          settlementId: id,
        })
        .then((r) => r.settlement),
    opts
  );
}

const createSettlementMutation = gql`
  mutation createSettlement($name: String!) {
    createSettlement(name: $name) {
      settlement {
        id
      }
    }
  }
`;

export function useCreateSettlementMutation(
  opts?: UseMutationOptions<{ id: string }, unknown, { name: string }>
) {
  const client = useRequiredContext(SessionContext).client;

  return useMutation(
    (vars: { name: string }) =>
      client
        .request<{
          createSettlement: { id: string };
        }>(createSettlementMutation, vars)
        .then((r) => r.createSettlement),
    opts
  );
}

const closeSettlementMutation = gql`
  mutation closeSettlement($id: String!) {
    closeSettlement(settlementId: $id) {
      settlement {
        id
      }
    }
  }
`;

export function useCloseSettlementMutation(
  opts?: UseMutationOptions<boolean, unknown, { id: string }>
) {
  const client = useRequiredContext(SessionContext).client;
  const queryClient = useQueryClient();

  return useMutation(
    (vars: { id: string }) =>
      client
        .request<{
          closeSettlement: { settlement: { id: string } | null };
        }>(closeSettlementMutation, vars)
        .then((r) => r.closeSettlement !== null),
    {
      ...opts,
      onSuccess(data, vars, ctx) {
        if (data) {
          queryClient.invalidateQueries(["settlement", vars.id]);
        }
        if (opts?.onSuccess) return opts.onSuccess(data, vars, ctx);
      },
    }
  );
}
