import classNames from "classnames";
import { useState } from "react";

import { Input } from "@/ui";

const ROLES: Array<{ id: string; name: string; description?: string }> = [
  {
    id: "barfunk",
    name: "Barfunk",
    description: "This gives you access to register new memberships.",
  },
  {
    id: "sm",
    name: "SM",
    description:
      'This gives you additional access to manage settlements ("oppgjør").',
  },
  {
    id: "admin",
    name: "Admin",
    description: "This gives you additional access to reports.",
  },
  {
    id: "superadmin",
    name: "Superadmin",
    description: "This includes full power over all functionality",
  },
];

export type LoginBoxErrors = {
  generic?: string;
  name?: string;
  password?: string;
};

export function LoginBox({
  defaultRole,
  onSubmit,
  loading,
  errors,
}: {
  defaultRole?: string;
  onSubmit: (props: { name: string; password: string; roleId: string }) => void;
  loading: boolean;
  errors?: LoginBoxErrors;
}) {
  const [activeTab, setActiveTab] = useState<string>();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  const currentRoleId = activeTab || defaultRole || "barfunk";

  const currentRole = ROLES.find((role) => currentRoleId === role.id);
  if (!currentRole) throw new Error("Tab set to missing role");

  return (
    <div className="max-w-prose">
      <div className="tabs">
        {ROLES.map((role) => (
          <a
            key={role.id}
            className={classNames("tab tab-lg tab-lifted", {
              "tab-active": currentRoleId === role.id,
              "text-primary": currentRoleId === role.id,
            })}
            onClick={(evt) => {
              evt.preventDefault();
              setActiveTab(role.id);
            }}
          >
            {role.name}
          </a>
        ))}
      </div>
      <form
        className="p-2"
        onSubmit={(evt) => {
          evt.preventDefault();
          onSubmit({ name, password, roleId: currentRole.id });
        }}
      >
        <p>{currentRole.description}</p>
        <Input
          id="login-name"
          label="Name"
          error={errors?.name}
          value={name}
          onChange={setName}
        />
        <Input
          type="password"
          id="login-password"
          label="Password"
          error={errors?.password}
          value={password}
          onChange={setPassword}
        />
        <div className="mt-4" />
        <button
          className={classNames("btn btn-primary", { loading })}
          disabled={loading}
        >
          Log in
        </button>
      </form>
    </div>
  );
}
