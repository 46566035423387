import { ReactNode } from "react";

import { usePageTitle } from "@/utils";

export function Box({
  title,
  className,
  children,
  actions,
  loading = false,
  primary = false,
}: {
  title: string;
  className?: string;

  /**
   * primary should be set to true if this is the primary box of this page.
   * This causes the <title> to be updated as well.
   */
  primary?: boolean;

  /**
   * loading can be set to true whenever something is being loaded on the page.
   */
  loading?: boolean;

  /**
   * actions is rendered immediately above the title.
   */
  actions?: ReactNode;

  children?: ReactNode;
}) {
  usePageTitle(primary ? title : undefined);

  return (
    <div
      className={`py-4 px-8 bg-base-100 shadow-lg rounded-lg border border-base-300 relative ${
        className || ""
      }`}
    >
      {actions && <div>{actions}</div>}
      <h2 className="title">{title}</h2>
      {children}
      {loading && (
        <div className="absolute inset-0 pointer-events-none bg-base-100 z-50 opacity-50">
          <div className="text-3xl text-center mt-8">Loading...</div>
        </div>
      )}
    </div>
  );
}
