import { createContext, ReactNode, useEffect, useMemo, useState } from "react";

export type Theme = {
  id: string;
  name: string;
};

const THEMES: Theme[] = [
  { id: "light", name: "Light" },
  { id: "acid", name: "Acid" },
  { id: "aqua", name: "Aqua" },
  { id: "autumn", name: "Autumn" },
  { id: "black", name: "Black" },
  { id: "bumblebee", name: "Bumblebee" },
  { id: "business", name: "Business" },
  { id: "cmyk", name: "CMYK" },
  { id: "corporate", name: "Corporate" },
  { id: "cupcake", name: "Cupcake" },
  { id: "cyberpunk", name: "Cyberpunk" },
  { id: "dracula", name: "Dracula" },
  { id: "emerald", name: "Emerald" },
  { id: "fantasy", name: "Fantasy" },
  { id: "forest", name: "Forest" },
  { id: "garden", name: "Garden" },
  { id: "halloween", name: "Halloween" },
  { id: "lemonade", name: "Lemonade" },
  { id: "lofi", name: "Lofi" },
  { id: "luxury", name: "Luxury" },
  { id: "night", name: "Night" },
  { id: "pastel", name: "Pastel" },
  { id: "retro", name: "Retro" },
  { id: "synthwave", name: "Synthwave" },
  { id: "valentine", name: "Valentine" },
  { id: "winter", name: "Winter" },
  { id: "wireframe", name: "Wireframe" },
];

const KEY = "rfiTheme";

const storedTheme = window.localStorage[KEY];
if (storedTheme) {
  document.documentElement.dataset.theme = storedTheme;
}

export type ThemeValue = {
  currentTheme: string;
  availableThemes: Theme[];
  changeTheme: (id: string) => void;
};

export const ThemeContext = createContext<ThemeValue | undefined>(undefined);

export function ThemeProvider({ children }: { children: ReactNode }) {
  const [currentTheme, setCurrentTheme] = useState(() => {
    return (
      window.localStorage[KEY] ||
      document.documentElement.dataset.theme ||
      "light"
    );
  });

  useEffect(() => {
    window.localStorage[KEY] = currentTheme;
    document.documentElement.dataset.theme = currentTheme;
  }, [currentTheme]);

  const value: ThemeValue = useMemo(
    () => ({
      currentTheme,
      availableThemes: THEMES,
      changeTheme: setCurrentTheme,
    }),
    [currentTheme]
  );

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
}
