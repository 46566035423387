import { Link } from "@/ui";

export type Crumb = {
  to?: string;
  title: string;
};

export function MenuCrumbs({ crumbs }: { crumbs: Crumb[] }) {
  return (
    <div className="text-sm breadcrumbs">
      <ul>
        {crumbs.map((crumb, idx) => (
          <li key={idx}>
            {crumb.to ? <Link to={crumb.to}>{crumb.title}</Link> : crumb.title}
          </li>
        ))}
      </ul>
    </div>
  );
}
