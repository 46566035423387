export type Changelog = {
  date: string;
  title: JSX.Element;
};

export const CHANGELOG: Changelog[] = [
  {
    date: "2022-09-17",
    title: <>Add support for removing memberships.</>,
  },
  {
    date: "2022-09-10",
    title: <>Add support for banned people.</>,
  },
  {
    date: "2022-09-03",
    title: <>Add support for searching existing members while registering.</>,
  },
  {
    date: "2022-09-03",
    title: <>Add support for filtering by name on "List memberships".</>,
  },
  {
    date: "2022-09-03",
    title: <>Improve formatting of NOKs.</>,
  },
  {
    date: "2022-09-03",
    title: <>Add more explanations to "Register membership".</>,
  },
  {
    date: "2022-08-30",
    title: <>Improve settlement listing.</>,
  },
  {
    date: "2022-06-23",
    title: <>Add functionality for closing settlement.</>,
  },
  {
    date: "2022-06-02",
    title: <>Add page for listing all settlements.</>,
  },
  {
    date: "2022-04-15",
    title: <>Add product menu.</>,
  },
  {
    date: "2022-04-02",
    title: <>Register new membership: Improve layout.</>,
  },
  {
    date: "2022-03-26",
    title: <>Register new membership: Add box for helping with age check.</>,
  },
  {
    date: "2022-03-26",
    title: <>Register new membership: Generate nicer membership codes.</>,
  },
  {
    date: "2022-03-13",
    title: <>Register new membership: List registered memberships below.</>,
  },
  {
    date: "2022-03-11",
    title: <>Show correct number for "Open settlements" in menu.</>,
  },
  { date: "2022-03-07", title: <>Add functionality for registering member.</> },
  { date: "2022-03-06", title: <>Add changelog page.</> },
  {
    date: "2022-03-06",
    title: (
      <>
        Add five more themes: Autumn, Business, Acid, Lemonade, Night, Winter.
      </>
    ),
  },
  {
    date: "2022-03-06",
    title: (
      <>
        Start work on "register membership" page: List and choose an open
        settlement.
      </>
    ),
  },
  {
    date: "2022-03-06",
    title: <>Add functionality for creating new settlement.</>,
  },
  {
    date: "2022-02-20",
    title: <>Implement authentiation: Log in and log out.</>,
  },
];
