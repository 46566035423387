import { useState } from "react";

import { useActiveMembershipQuery } from "@/data";
import { MembershipType } from "@/types";
import { Box, MembershipTable } from "@/ui";

const TYPE_ALTERNATIVES: Array<{
  type: MembershipType | undefined;
  label: string;
}> = [
  { type: undefined, label: "All" },
  { type: "SEMESTER", label: "Semester" },
  { type: "LIFETIME", label: "Lifetime" },
];

const BEFORE_RE = /^\d{4}-\d{2}-\d{2}$/;

export function ListMembershipsPage() {
  const [type, setType] = useState<MembershipType>();
  const [before, setBefore] = useState<string>("");
  const [name, setName] = useState("");
  const validBefore = BEFORE_RE.exec(before) ? before : undefined;

  const result = useActiveMembershipQuery({ type, before: validBefore });

  let memberships = result.data || [];

  const nameFilter = name
    .toLowerCase()
    .split(/\s+/)
    .filter((part) => part.length > 0);

  if (nameFilter.length > 0) {
    memberships = memberships.filter((mem) =>
      nameFilter.every((filter) => mem.name.toLowerCase().includes(filter))
    );
  }

  return (
    <Box title="Memberships" primary loading={result.isLoading}>
      <div className="flex">
        <label className="mr-8">
          <span className="mr-2">Name</span>
          <input
            type="text"
            className="input input-sm input-bordered"
            value={name}
            onChange={(evt) => {
              setName(evt.target.value);
            }}
          />
        </label>
        <label className="mr-8">
          <span className="mr-2">Registered before</span>
          <input
            type="text"
            className="input input-sm input-bordered invalid:border-error"
            placeholder="YYYY-MM-DD"
            value={before}
            pattern={BEFORE_RE.source}
            onChange={(evt) => {
              setBefore(evt.target.value);
            }}
          />
        </label>
        {TYPE_ALTERNATIVES.map((alt) => (
          <label className="flex items-center mr-8" key={alt.type || "both"}>
            <input
              type="radio"
              className="radio ml-2"
              checked={type === alt.type}
              onChange={(evt) => {
                if (evt.target.checked) {
                  setType(alt.type);
                }
              }}
            />
            <span className="ml-2">{alt.label}</span>
          </label>
        ))}
      </div>
      <div className="mt-4"></div>
      <MembershipTable memberships={memberships} />
    </Box>
  );
}
