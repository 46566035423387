import { Settlement } from "@/types";
import { Link } from "@/ui";
import { formatDateTime, formatNOK } from "@/utils";

export function SettlementTable({
  settlements,
  showClosedAt,
  linkToOpen = false,
}: {
  settlements: Settlement[];
  showClosedAt: boolean;
  linkToOpen?: boolean;
}) {
  if (settlements.length === 0) {
    return <div className="alert">No settlements to show.</div>;
  }

  return (
    <table className="table table-zebra w-full">
      <thead>
        <tr>
          <th>Name</th>
          <th>Created by</th>
          <th>Created at</th>
          {showClosedAt && <th>Closed at</th>}
          <th>Memberships</th>
        </tr>
      </thead>
      <tbody>
        {settlements.map((settlement) => (
          <tr key={settlement.id}>
            <td>
              <Link
                to={
                  linkToOpen
                    ? `/settlements/open/${settlement.id}`
                    : `/settlements/${settlement.id}`
                }
                className="link"
              >
                {settlement.name}
              </Link>
            </td>
            <td>{settlement.creator.name}</td>
            <td>{formatDateTime(new Date(settlement.createdAt))}</td>
            {showClosedAt && (
              <td>
                {settlement.closedAt
                  ? formatDateTime(new Date(settlement.closedAt))
                  : "-"}
              </td>
            )}
            <td>
              {settlement.totalCount} ({formatNOK(settlement.totalPrice)})
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
