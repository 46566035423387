import type { ReactNode } from "react";
import UniversalRouter from "universal-router/sync";

import { Authorization } from "@/controllers";
import {
  ChangelogPage,
  EditProductMenuPage,
  RegisterPage,
  RootPage,
  SettlementsNewPage,
  ListMembershipsPage,
  ListSettlementsPage,
  SettlementPage,
  BannedPeoplePage,
} from "@/pages";

export type Route = {
  node: ReactNode;
  isRoot?: true;
  crumbTitle?: string;
  menuItem?: [string, string];
};

export const router = new UniversalRouter<Route>([
  {
    action: () => ({ node: <RootPage />, isRoot: true }),
  },
  {
    path: "/changelog",
    action: () => ({ node: <ChangelogPage />, crumbTitle: "Changelog" }),
  },
  {
    path: "/memberships/register",
    action: () => ({
      menuItem: ["memberships", "register"],
      node: (
        <Authorization permission="register">
          <RegisterPage />
        </Authorization>
      ),
    }),
  },
  {
    path: "/memberships",
    action: () => ({
      menuItem: ["memberships", "list"],
      node: (
        <Authorization permission="reports">
          <ListMembershipsPage />
        </Authorization>
      ),
    }),
  },
  {
    path: "/banned_people",
    action: () => ({
      menuItem: ["memberships", "banned_people"],
      node: (
        <Authorization permission="admin">
          <BannedPeoplePage />
        </Authorization>
      ),
    }),
  },
  {
    path: "/settlements/new",
    action: () => ({
      menuItem: ["settlements", "new"],
      node: (
        <Authorization permission="settlements">
          <SettlementsNewPage />
        </Authorization>
      ),
    }),
  },
  {
    path: "/settlements",
    action: () => ({
      menuItem: ["settlements", "list"],
      node: (
        <Authorization permission="settlements">
          <ListSettlementsPage />
        </Authorization>
      ),
    }),
  },
  {
    path: "/settlements/:id",
    action: (context) => ({
      menuItem: ["settlements", "list"],
      node: (
        <Authorization permission="settlements">
          <SettlementPage id={context.params.id as string} />
        </Authorization>
      ),
    }),
  },
  {
    path: "/menu/edit",
    action: () => ({
      menuItem: ["menu", "edit"],
      node: (
        <Authorization permission="admin">
          <EditProductMenuPage />
        </Authorization>
      ),
    }),
  },
]);
