import classNames from "classnames";

export function Input({
  id,
  label,
  value,
  error,
  onChange,
  type = "text",
}: {
  id: string;
  label: string;
  value: string;
  error?: string;
  onChange: (val: string) => void;
  type?: string;
}) {
  return (
    <div className="form-control">
      <label className="label" htmlFor={id}>
        <span className="label-text">{label}</span>
      </label>
      <input
        className={classNames("input input-bordered", {
          "input-error": error !== undefined,
        })}
        value={value}
        onChange={(evt) => onChange(evt.target.value)}
        id={id}
        type={type}
      />
      {error && (
        <label className="label">
          <span className="label-text-alt">{error}</span>
        </label>
      )}
    </div>
  );
}
