import { GraphQLClient } from "graphql-request";

import { API_URL as NullableAPI_URL } from "../env";

if (typeof NullableAPI_URL !== "string") {
  throw new Error("Expected RFI_API_URL to be set");
}

const API_URL: string = NullableAPI_URL;

export function createGraphQLClient({
  token,
  onDeauthenticate,
}: { token?: string; onDeauthenticate?: () => void } = {}) {
  const headers = new Headers();
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }

  const client = new GraphQLClient(API_URL + "/graphql", { headers });
  const request = client.request as any;
  client.request = ((doc: any, vars: any) => {
    return request.call(client, doc, vars).catch((err: unknown) => {
      if (
        isObject(err) &&
        isObject(err.response) &&
        isObject(err.response.extensions) &&
        typeof err.response.extensions.customError === "string"
      ) {
        const { customError } = err.response.extensions;
        if (customError === "notAuthenticated" && onDeauthenticate) {
          onDeauthenticate();
        }
        throw new CustomError(customError);
      }
      throw err;
    });
  }) as any;
  return client;
}

export class CustomError extends Error {}

function isObject(val: unknown): val is Record<string, unknown> {
  return val !== null && typeof val === "object";
}

export type ErrorMapping<T> = Record<string, (name: string) => T>;

export function handleError<TError = unknown>(
  err: unknown,
  errorMapping?: ErrorMapping<TError>
): TError {
  if (errorMapping && err instanceof CustomError) {
    const key = err.message;
    if (key in errorMapping) {
      return errorMapping[key](key);
    }
  }
  throw err;
}
