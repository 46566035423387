import { Component, ReactNode } from "react";

export class ErrorBoundary extends Component<
  { children: ReactNode },
  { errorMessage?: string }
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: any) {
    return { errorMessage: error.message };
  }

  render() {
    if (this.state.errorMessage !== undefined) {
      return (
        <div className="flex flex-col p-8 items-center justify-center">
          <div className="p-8 text-3xl">Something went horribly wrong!</div>
          <a href="/" className="underline text-primary hover:no-underline">
            Go back and try again.
          </a>
        </div>
      );
    }

    return this.props.children;
  }
}
