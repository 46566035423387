import { MenuSection } from "./types";

export type Menu = MenuSection[];

export type MenuProps = {
  openSettlementCount: number | null;
  includeExperimental?: boolean;
};

export function buildMenu({
  openSettlementCount,
  includeExperimental = true,
}: MenuProps): Menu {
  return [
    ...(includeExperimental
      ? [
          {
            id: "memberships",
            title: "Memberships",
            children: [
              {
                id: "register",
                title: "Register memberships",
                to: "/memberships/register",
              },
              {
                id: "list",
                title: "List memberships",
                to: "/memberships",
              },
              {
                id: "banned",
                title: "Banned people",
                to: "/banned_people",
              },
            ],
          },
          {
            id: "settlements",
            title: "Settlements",
            children: [
              {
                id: "open",
                title: openSettlementCount
                  ? `Open settlements (${openSettlementCount})`
                  : "Open settlements",
                to: "/settlements?open=true",
              },
              {
                id: "new",
                title: "New settlement",
                to: "/settlements/new",
              },
              {
                id: "list",
                title: "List settlements",
                to: "/settlements",
              },
            ],
          },
          {
            id: "reports",
            title: "Reports",
            children: [
              {
                id: "week",
                title: "Memberships by week",
                to: "/reports/week",
              },
              {
                id: "semester",
                title: "Memberships by semester",
                to: "/reports/semester",
              },
            ],
          },
          {
            id: "users",
            title: "Users",
            children: [
              {
                id: "sessions",
                title: "List sessions",
                to: "/sessions",
              },
              {
                id: "password",
                title: "Change password",
                to: "/roles",
              },
            ],
          },
        ]
      : []),
    {
      id: "menu",
      title: "Product menu",
      children: [
        {
          id: "edit",
          title: "Edit product menu",
          to: "/menu/edit",
        },
      ],
    },
  ];
}
